<template>
    <modal ref="modalVerMas" :titulo="titulo">
        <div class="row mx-0">
            <div class="col-12 text-center text-general f-600 f-18">
                {{ fecha }}
            </div>
        </div>
        <div class="row mx-0 py-3" />
        <div v-for="(data,p) in array" :key="p" class="row mx-0">
            <img :src="data.foto" width="50" height="50" class="border br-12" />
            <div class="col">
                <p class="text-genera f-15 text-general">
                    {{ data.nombre }}
                </p>
                <div class="row mx-0">
                    <p class="col-auto text-general2">
                        {{ separadorNumero(data.total) }}
                    </p>
                    <p class="col-auto text-general">
                        {{ agregarSeparadoresNumero(data.cantidad) }} Movimientos
                    </p>
                </div>
                <div class="row mx-0" style="height:10px;">
                    <div class="bg-gris h-100 br-20" :style="`width:${porcentajesBordes(data.porcentaje_max_cantidad)}%;`" />
                </div>
            </div>
            <div class="col-auto text-general f-600 f-18 d-flex align-items-end">
                {{ agregarSeparadoresNumero(data.porcentaje_cantidad, 1) }}%
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    props: {
        titulo: {
            type: String,
            default: ''
        }
    },
    data(){
        return {
            array: [],
            fecha: 'Durante: 20 Jun. 2020 - 5 Nov.2020',
        }
    },
    methods: {
        toggle(array, fecha){
            this.array = array
            this.fecha = fecha
            this.$refs.modalVerMas.toggle();
        }
    }
}
</script>
